<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-card class="elevation-0 overflow-auto" tile :height="height">
      <v-card-text>
        <v-row no-gutters justify="center" class="ma-0">
          <v-col cols="12" xs="12" sm="12" md="7" align="center">
            <v-img max-width="600px" src="@/assets/Background.jpg" class="ma-2"></v-img>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="5">
            <v-card class="elevation-0 py-5 center" max-width="450">
              <v-card-title class="pb-4 pt-1 mt-0">
                <v-row class="text-center">
                  <v-col cols="12" class="py-0" align="center">
                    <v-img src="@/assets/VOZCARE.png" width="170"></v-img>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <div v-if="currentTitle != 'Change Temporary Password'" class="mt-5">
                      <h2 class="dark_primary--text font-weight-regular">
                        {{ currentTitle }}
                      </h2>
                    </div>
                    <div v-else class="mt-5">
                      <h2 class="dark_primary--text font-weight-regular">
                        {{ currentTitle }}
                      </h2>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-window v-model="step" class="px-3">
                  <v-window-item :value="1">
                    <v-card-text>
                      <v-form ref="form" lazy-validation class="mt-2">
                        <v-text-field outlined dense label="Email ID *" prepend-inner-icon="mdi-account" :rules="[(v) => !!v || 'Email ID is required']" v-model="Login.EmailID"></v-text-field>
                        <v-text-field
                          outlined
                          dense
                          label="Password *"
                          :type="isPwd ? 'text' : 'password'"
                          @click:append="isPwd = !isPwd"
                          required
                          :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock"
                          :rules="[(v) => !!v || 'Password is required']"
                          v-model="Login.Password"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="py-0 mt-n4">
                      <v-checkbox dense class="my-0 py-0 ml-1" v-model="rememberme" label="Remember Me" :disabled="Login.EmailID === '' && Login.Password === ''" />
                      <v-spacer></v-spacer>
                      <v-btn small text class="FontSize mb-4" color="primary text-capitalize fontStyle" @click="step = 3">Forgot Password ?</v-btn>
                    </v-card-actions>
                    <v-card-actions class="pt-0 pb-2">
                      <v-btn block :loading="loading" tile class=" " color="primary" @click="ValidateSignUpForm()">Login</v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-card-text>
                      <v-form ref="formCP" lazy-validation>
                        <v-text-field
                          class=""
                          outlined
                          dense
                          label="Enter New Password *"
                          :type="isPwdNew ? 'text' : 'password'"
                          @click:append="isPwdNew = !isPwdNew"
                          required
                          :append-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock"
                          :rules="[(v) => !!v || 'Password is required']"
                          v-model="Password.NewPassword"
                        ></v-text-field>
                        <v-text-field
                          class=""
                          outlined
                          dense
                          label="Confirm New Password *"
                          :type="isPwdConfirm ? 'text' : 'password'"
                          @click:append="isPwdConfirm = !isPwdConfirm"
                          required
                          :append-icon="isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock"
                          :rules="[(v) => !!v || 'Password is required']"
                          v-model="Password.ConfirmPassword"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0 pb-8 px-4">
                      <v-btn block :loading="loading" tile class=" " color="primary" @click="ValidateConfirmPasswordForm()">Login</v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="3">
                    <v-card-text>
                      <v-form ref="formOTP">
                        <div class="text-center">Please Enter The Registered Email</div>
                        <v-text-field dense outlined label="Email" v-model="OTPEmail" :rules="[(v) => !!v || 'Email ID is required']" class="mt-4"> </v-text-field>
                        <div class="FontSize text-center">*Please enter the e-mail used for vozcare login/registration</div>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="primary fontStyle" small @click="step = 1">Back To Sign In</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary fontStyle" small @click="ValidateOTP()">Next</v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="4">
                    <v-card-text>
                      <v-form ref="formConfirmOTP">
                        <div class="text-center">Enter the vozcare Activation Code</div>
                        <v-text-field dense outlined label="Code *" v-model="ConfirmOTP" :rules="[(v) => !!v || 'Code is required']" class="mt-4"> </v-text-field>
                        <v-text-field
                          dense
                          outlined
                          label="Enter New Password *"
                          v-model="ConfirmNewPassword"
                          :rules="[(v) => !!v || 'Password is required']"
                          class=""
                          :type="isPwdConfirmCode ? 'text' : 'password'"
                          @click:append="isPwdConfirmCode = !isPwdConfirmCode"
                          required
                          :append-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock"
                        >
                        </v-text-field>
                        <v-text-field
                          dense
                          outlined
                          label="Re-enter New Password *"
                          v-model="ReenterNewPassword"
                          :rules="[(v) => !!v || 'Password is required']"
                          class=""
                          :type="isPwdConfirmCode ? 'text' : 'password'"
                          @click:append="isPwdConfirmCode = !isPwdConfirmCode"
                          required
                          :append-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock"
                        >
                        </v-text-field>
                        <div class="FontSize text-center">Enter the vozcare Activation Code sent to your registered Email.</div>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="primary" small @click="step = 1">Back To Sign In</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" small @click="ValidateEnteredOTP()">Next</v-btn>
                    </v-card-actions>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    height: 0,
    step: 1,
    Login: {
      EmailID: "",
      Password: "",
    },
    isPwd: false,
    loading: false,
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    isPwdNew: false,
    isPwdConfirm: false,
    SnackBarComponent: {},
    OTPEmail: "",
    ConfirmOTP: "",
    ConfirmNewPassword: "",
    ReenterNewPassword: "",
    isPwdConfirmCode: false,
    rememberme: false,
  }),
  watch: {
    rememberme(val) {
      if (val == true) {
        localStorage.setItem("currentuseremail", JSON.stringify(this.Login));
      } else if (val == false) {
        delete localStorage.currentuseremail;
      }
    },
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "BMS - Sign In";
        case 2:
          return "Change Temporary Password";
        case 3:
          return "Forgot Password ?";
        case 4:
          return "Forgot Password ?";
        default:
          return "Account created";
      }
    },
  },
  mounted() {
    this.CheckRememberMe();
    this.height = window.innerHeight;
  },
  methods: {
    CheckRememberMe() {
      if (localStorage.getItem("currentuseremail") != null) {
        this.Login = JSON.parse(localStorage.getItem("currentuseremail"));
        this.rememberme = true;
      }
    },
    ConfirmOTPMethod() {
      Auth.forgotPasswordSubmit(this.OTPEmail, this.ConfirmOTP, this.ConfirmNewPassword)
        .then(() => {
          this.$refs.formConfirmOTP.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
          };
          this.step = 1;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    ValidateEnteredOTP() {
      if (this.$refs.formConfirmOTP.validate()) {
        if (this.ReenterNewPassword == this.ConfirmNewPassword) {
          this.ConfirmOTPMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password Does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
        };
      }
    },
    ValidateOTP() {
      if (this.$refs.formOTP.validate()) {
        this.SendOTPEmail();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Registered Email",
        };
      }
    },
    SendOTPEmail() {
      Auth.forgotPassword(this.OTPEmail)
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent To Registered Email",
          };
          this.step = 4;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    async ConfirmPassword() {
      try {
        this.loading = true;
        const result = await Auth.completeNewPassword(this.user, this.Password.ConfirmPassword)
          .then((user) => {
            return user;
          })
          .catch((e) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: e,
            };
            return e;
          });
        if (result.username) {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.$refs.formCP.reset();
          this.ActivateMethod();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    async SignIn() {
      try {
        this.loading = true;
        this.user = await Auth.signIn(this.Login.EmailID, this.Login.Password);
        if (this.user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.step = 2;
          this.loading = false;
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
        } else {
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Login Successful",
          };
          this.ActivateMethod();
          this.loading = false;
        }
      } catch (error) {
        console.log("error", error);
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    ActivateMethod() {
      setTimeout(() => this.$router.push("/LandingPage"), 500);
    },
    ValidateConfirmPasswordForm() {
      if (this.$refs.formCP.validate()) {
        if (this.Password.NewPassword == this.Password.ConfirmPassword) {
          this.ConfirmPassword();
        } else {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    ValidateSignUpForm() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
      // this.$router.push("/LandingPage");
    },
  },
};
</script>

<style>
.center {
  position: absolute;
  top: 20%;
}
</style>
