<template>
  <v-app>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-app-bar dense app clipped-left color="white" elevation="1">
      <v-app-bar-nav-icon
        color="primary"
        v-if="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' || $vuetify.breakpoint.name == 'xs'"
        @click="navDrawer = !navDrawer"
      ></v-app-bar-nav-icon>
      <v-card class="elevation-0">
        <v-img src="@/assets/VOZCARE.png" width="150"></v-img>
      </v-card>
      <h2>BMS</h2>
      <v-spacer />
      <span>
        {{ $store.getters.get_user_email }}
      </span>
      <v-tooltip bottom color="red">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="SignOut">
            <v-icon color="red" dark>mdi-exit-to-app</v-icon>
          </v-btn>
        </template>
        <span class="white--text">Sign Out ?</span>
      </v-tooltip>
    </v-app-bar>
    <v-navigation-drawer v-model="navDrawer" app clipped width="130px" color="primary" dark>
      <div class="mt-2" v-for="(item, idx) in RouterList" :key="idx">
        <v-card class="elevation-0 cursorPointer mx-1" :color="item.active ? 'white black--text' : 'primary'" @click="checkRouteMethod(item)">
          <v-card-actions class="py-0 pl-0 FontSize">
            <v-icon small class="mx-2" :color="item.active ? 'black' : 'white'"> {{ item.MenuICon }}</v-icon>
            {{ item.MenuName }}
          </v-card-actions>
        </v-card>
      </div>
    </v-navigation-drawer>
    <v-main>
      <div ref="home">
        <v-card class="elevation-0" @drop.prevent="onDrop($event)" @dragover.prevent="onDragOver($event)" @dragenter.prevent="dragover = true" @dragleave.prevent="onDragLeave($event)">
          <router-view v-if="dragover == false" />
          <v-card class="elevation-0" v-else :height="Height">
            <v-card fill-height fluid :height="`${Height - 120}`" class="elevation-0 borderDotted ma-8">
              <v-container fill-height fluid>
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <v-icon size="60px" color="primary">mdi-paperclip-plus</v-icon>
                    <h2 class="mt-2">Please Drag And Drop An Attachment To Report And Issue</h2>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-card>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import * as htmlToImage from "html-to-image";
import { Auth } from "aws-amplify";
import RouterList from "@/JsonFiles/RouterList.json";
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { GetCurrentUserDetailsQuery } from "@/mixins/GetCurrentUserDetailsQuery.js";

export default {
  components: {
    Overlay,
    Snackbar,
  },
  data: () => ({
    navDrawer: true,
    overlay: false,
    RouterList: [],
    selectedItem: 0,
    user_Status: null,
    dialogCreateIssue: false,
    dragover: false,
    Height: 0,
    selectedFilesFromDrop: [],
    SnackBarComponent: {},
  }),
  watch: {},
  mounted() {
    this.GetCurrentUserDetailsMethod();
    this.RouterList = [];
    for (let i = 0; i < RouterList.length; i++) {
      this.RouterList.push({ ...RouterList[i] });
    }
    this.$forceUpdate();
  },
  mixins: [GetCurrentUserDetailsQuery],
  methods: {
    checkRouteMethod(item) {
      if (this.$route.name != item.MenuRoute && item.has_child_routes == false) {
        for (let i = 0; i < this.RouterList.length; i++) {
          if (item.MenuRoute == this.RouterList[i].MenuRoute) {
            this.RouterList[i].active = true;
          } else {
            this.RouterList[i].active = false;
          }
          if (this.$route.name != item.MenuRoute) {
            this.$router.push(item.MenuRoute);
          }
        }
      }
      this.$forceUpdate();
    },
    htmlToImageMethod() {
      this.overlay = true;
      this.$forceUpdate();
      this.selectedFilesFromDrop = [];
      var self = this;
      setTimeout(() => {
        htmlToImage.toBlob(this.$refs.home).then(function (blob) {
          blob.name = `ScreenShot${new Date().getTime()}.png`;
          self.selectedFilesFromDrop.push(blob);
          let object = {
            selectedFilesFromDrop: self.selectedFilesFromDrop,
            routeName: self.$route.name,
          };
          self.$store.commit("SET_ISSUE_DETAILS", object);
          self.$store.commit("SET_PREVIOUS_ROUTE", self.$route.name);
          self.$router.push("ReportIssue");
        });
      }, 2000);
    },
    dialogCreateIssueEmit(Toggle) {
      this.loading = false;
      this.dialogCreateIssue = false;
      this.selectedFiles = [];
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
    async SignOut() {
      await Auth.signOut().then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>
