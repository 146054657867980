<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
export default {
  mounted() {
    history.pushState(null, null, "");
    window.addEventListener("popstate", () => window.location.assign("#"));
  },
};
</script>
<style>
.BorderPrimary {
  border: 2px dashed #3d3d3d !important;
}
.DetailsSize {
  font-size: 14px;
}
.v-input .v-label {
  font-size: 12px;
}
.PrimaryFontColor {
  color: #424242;
  font-size: 20px;
}
.SubRoute-Item {
  color: white;
}
.Route-Item {
  color: white;
}
.PrimaryFontColorXSmall {
  color: #424242;
  font-size: 12px;
}
.PrimaryFontColorSmall {
  color: #424242;
  font-size: 15px;
}
.heading1 {
  font-size: 26px;
}
.heading2 {
  font-size: 16px;
  color: #1c1c1c;
}
.heading2 {
  font-size: 24px;
}
.blackAndBoldFont {
  font-weight: bold;
  color: #1c1c1c;
}
.field_height.v-text-field .v-input__control .v-input__slot {
  min-height: 12px !important;
  display: flex !important;
  align-items: center !important;
}
.field_label_size.v-input .v-label {
  font-size: 12px;
}

.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}
.borderRadius {
  border-radius: 5px !important;
}
.FontSize {
  font-size: 12px !important;
}
.FontSizeSmall {
  font-size: 10px !important;
}
.GreenHeader {
  color: green;
}
.RedHeader {
  color: red !important;
}
.maxWidth {
  max-width: 200px !important;
}
.maxWidthLarge {
  max-width: 300px !important;
}
.maxWidthSmall {
  max-width: 120px !important;
}
.editorHeight {
  height: 450px !important;
}
.editorHeightSM {
  height: 50px !important;
}
.borderRadiusZero {
  border-radius: 0px !important;
  border: 1px solid rgb(206, 202, 202) !important;
}
.borderRadiusZeroColor {
  border-radius: 0px !important;
  border: 1px solid #9cb2b2 !important;
}

.fontSizeSmall {
  font-size: 10px !important;
}
.img-upload-input {
  display: none;
  z-index: -9999;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.v-label {
  margin-top: -4px;
}
</style>
