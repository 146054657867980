/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const GetCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: GetCurrentUserDetailsInput) {
    GetCurrentUserDetails(input: $input)
  }
`;
export const ListAuditLogs = /* GraphQL */ `
  query ListAuditLogs($input: ListAuditLogsInput) {
    ListAuditLogs(input: $input)
  }
`;
export const ListUsers = /* GraphQL */ `
  query ListUsers($input: ListUsersInput) {
    ListUsers(input: $input)
  }
`;
export const ListPortalUsers = /* GraphQL */ `
  query ListPortalUsers($input: ListPortalUsersInput) {
    ListPortalUsers(input: $input)
  }
`;
export const ListCustomers = /* GraphQL */ `
  query ListCustomers($input: ListCustomersInput) {
    ListCustomers(input: $input)
  }
`;
export const GetCustomerId = /* GraphQL */ `
  query GetCustomerId($input: GetCustomerIdInput) {
    GetCustomerId(input: $input)
  }
`;
export const ListCountries = /* GraphQL */ `
  query ListCountries($input: ListCountriesInput) {
    ListCountries(input: $input)
  }
`;
export const ListModules = /* GraphQL */ `
  query ListModules($input: ListModulesInput) {
    ListModules(input: $input)
  }
`;
export const ListPackages = /* GraphQL */ `
  query ListPackages($input: ListPackagesInput) {
    ListPackages(input: $input)
  }
`;
export const ListForms = /* GraphQL */ `
  query ListForms($input: ListFormsInput) {
    ListForms(input: $input)
  }
`;
export const ListGroupRoles = /* GraphQL */ `
  query ListGroupRoles($input: ListGroupRolesInput) {
    ListGroupRoles(input: $input)
  }
`;
export const ListSubModules = /* GraphQL */ `
  query ListSubModules($input: ListSubModulesInput) {
    ListSubModules(input: $input)
  }
`;
export const ListSubSubModules = /* GraphQL */ `
  query ListSubSubModules($input: ListSubSubModulesInput) {
    ListSubSubModules(input: $input)
  }
`;
