import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    EmailID: "",
    getIssueDetails: {},
    getPreviousRoute: "",
    currentUserDetails: {},
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_issue_details: (state) => {
      return state.getIssueDetails;
    },
    get_previous_route: (state) => {
      return state.getPreviousRoute;
    },
    get_current_user_details: (state) => {
      return state.currentUserDetails;
    },
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_ISSUE_DETAILS(state, getIssueDetails) {
      state.getIssueDetails = getIssueDetails;
    },
    SET_PREVIOUS_ROUTE(state, route) {
      state.getPreviousRoute = route;
    },
    SET_CURRENTUSER_DETAILS(state, user) {
      state.currentUserDetails = user;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
