import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/MainHomePages/LoginPage.vue";
import LandingPage from "../views/MainHomePages/LandingPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: LandingPage,
    children: [
      {
        path: "/Organizations",
        name: "Organizations",
        component: () => import("@/views/ChildHomePages/Organizations.vue"),
      },
      {
        path: "/Packages",
        name: "Packages",
        component: () => import("@/views/ChildHomePages/Packages.vue"),
      },
      {
        path: "/Modules",
        name: "Modules",
        component: () => import("@/views/ChildHomePages/Modules.vue"),
      },

      {
        path: "/Countries",
        name: "Countries",
        component: () => import("@/views/ChildHomePages/Countries.vue"),
      },
      {
        path: "/Users",
        name: "Users",
        component: () => import("@/views/ChildHomePages/Users.vue"),
      },
      {
        path: "/AuditLogs",
        name: "AuditLogs",
        component: () => import("@/views/ChildHomePages/AuditLogs.vue"),
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
