import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0f99da",
        dark_primary: "#243D25",
        light_primary: "#4169e1",
        secondary: "#F77E21",
        light_secondary: "#14C38E",
      },
      dark: {
        primary: "#f4813f",
        secondary: "#f1556a",
        theme: "#000000",
      },
    },
  },
});
